import React from "react"
import Layout from "../components/layout"

export default class Offer extends React.Component {
    render() {
        return (
            <Layout className="solid">
                <div className="container">
                    <div className="simple-text">
                        <h3>Pobierz naszą ofertę </h3>
                        <p>Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget sapien a
                            cursus. Sed id metus et libero mattis iaculis. Aliquam pretium viverra velit at accumsan. Ut
                            velit ligula, euismod a ultricies eu, placerat rutrum turpis. Aenean convallis orci vel
                            libero condimentum viverra. Maecenas egestas accumsan sapien. Cras laoreet neque a varius
                            fringilla. In quis magna laoreet, vestibulum sem vitae, eleifend diam. Aliquam sed metus
                            iaculis, imperdiet nisl non, elementum lectus. Quisque hendrerit luctus porttitor. In ut
                            feugiat lorem.Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget
                            sapien a cursus.</p>
                    </div>
                </div>
                <div className="container flex">
                    <div className="widget widget--pdf">
                        <h3>Katalog falve 2019</h3>
                        <p>Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget sapien a
                            cursus. </p>
                        <a href="#" className="btn btn--yellow">Pobierz <span className="icon-download icon"></span></a>
                    </div>
                    <div className="widget widget--doc">
                        <h3>Serwis falve 2019</h3>
                        <p>Quisque congue libero arcu, sed condimentum arcu vehicula at. Sed mollis eget sapien a
                            cursus. </p>
                        <a href="#" className="btn btn--yellow">Pobierz <span className="icon-download icon"></span></a>
                    </div>
                </div>
            </Layout>
        )
    }
}
